<!-- 菜单 -->
<template>
  <div id="menu">
    <el-menu :default-active="routePath" router class="el-menu-vertical-demo" @select="menuChange">
      <el-menu-item index="/public/live_data">
        <i class="el-icon-document-copy"></i>
        <span slot="title">实时数据</span>
      </el-menu-item>
      <el-submenu index="/public_data">
        <template slot="title">
          <i class="el-icon-s-data"></i>
          <span slot="title">历史数据</span>
        </template>
        <el-menu-item index="/public/history_data1?type=sensor">历史数据</el-menu-item>
        <el-menu-item index="/public/queryRain">雨量统计</el-menu-item>
        <!-- <el-menu-item index="/public/average_data">历史均值</el-menu-item> -->
        <!-- <el-menu-item index="/public/alarm_data">报警信息</el-menu-item> -->
        <!-- <el-menu-item index="/public/standard_data">超标数据</el-menu-item> -->
        <el-menu-item index="/public/history_data2?type=online">设备状态</el-menu-item>
      </el-submenu>
      <!-- <el-menu-item index="/second/main_page">
				<i class="el-icon-picture"></i>
				<span slot="title">水电生生态流量管理系统</span>
			</el-menu-item> -->
      <!-- <el-menu-item index="/public/picture_data">
				<i class="el-icon-picture"></i>
				<span slot="title">图片浏览</span>
			</el-menu-item>-->
      <!-- <el-menu-item index="/public/video_data"><i class="el-icon-camera-solid"></i><span slot="title">视频监控</span></el-menu-item> -->
      <el-menu-item index="/public/gps_data">
        <i class="el-icon-location"></i>
        <span slot="title">GPS地图</span>
      </el-menu-item>
      <!-- <el-menu-item index="/public/typhoon_path">
				<i class="el-icon-location"></i>
				<span slot="title">台风路径</span>
			</el-menu-item> -->
      <!-- <el-menu-item index="/second/history_search">
				<i class="el-icon-location"></i>
				<span slot="title">历史查询</span>
			</el-menu-item> -->
      <el-submenu index="/public_mng">
        <template slot="title">
          <i class="el-icon-menu"></i>
          <span slot="title">数据管理</span>
        </template>
        <el-menu-item index="/user/org_mng" v-if="user.isAdmin=='1'||user.isManager=='1'">组织管理</el-menu-item>
        <el-menu-item index="/user/user_mng" v-if="user.isAdmin=='1'||user.isManager=='1'">用户管理</el-menu-item>
        <el-menu-item index="/user/device_mng" v-if="user.isAdmin=='1'||user.isManager=='1'">设备管理</el-menu-item>
        <el-menu-item index="/admin/jcoUpdate" v-if="(user.isAdmin=='1'||user.isManager=='1') && is_def">jco升级</el-menu-item>
        <el-menu-item index="/admin/remoteLog" v-if="user.isAdmin=='1'||user.isManager=='1'">设备远程日志</el-menu-item>
        <el-menu-item index="/admin/productDoc" v-if="(user.isAdmin=='1'||user.isManager=='1') && is_def">产品文档</el-menu-item>
        <el-menu-item index="/public/device_vers" v-if="(user.isAdmin=='1'||user.isManager=='1') && is_def">设备资料</el-menu-item>
        <el-menu-item index="/admin/platformLog" v-if="user.isAdmin=='1'||user.isManager=='1'">平台日志</el-menu-item>
        <!-- <el-menu-item index="/user/device_config">设备配置</el-menu-item> -->
        <el-menu-item index="/user/device_config" v-if="user.isAdmin=='1'||user.isManager=='1'">设备GPS配置
        </el-menu-item><!-- 普管权限 -->
        <el-menu-item index="/admin/device_config" v-if="user.isAdmin=='1'">视频配置</el-menu-item><!-- 超管权限 -->
        <el-menu-item index="/admin/develop_manage" v-if="user.isAdmin=='1' || user.isManager=='1'">布防管理</el-menu-item><!-- 布防管理 -->
        <el-menu-item index="/admin/message" v-if="user.isAdmin=='1' || user.isManager=='1'">短信</el-menu-item><!-- 短信 -->
        <el-menu-item index="/admin/link_manage" v-if="user.isAdmin=='1' || user.isManager=='1'">联动管理</el-menu-item><!-- 联动管理 -->
        <el-menu-item index="/dayawan/export_dmData">导出大亚湾内涝数据</el-menu-item><!-- 超管权限 -->
        <el-menu-item index="/dayawan/export_data">导出大亚湾水库数据</el-menu-item><!-- 超管权限 -->
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      routePath: '',
      user: '',
      is_def: this.$store.getters['settingStore/getPlatform']//是否是自己平台
    }
  },
  methods: {
    menuChange(index) {
      this.$emit('change', index);
    }
  },
  watch: {
    $route() {
      if (this.$route.meta.isSubPage) {
        this.routePath = this.$route.meta.parent;
      } else {
        this.routePath = this.$route.path;
      }
    }
  },
  mounted() {
    this.routePath = this.$route.path;
    this.user = this.common.cGetUser();
  }
}
</script>

<style lang="scss">
</style>
